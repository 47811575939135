jQuery(document).ready(function($) {
	$('[data-selector]').change(function() {
		var inputs = $('[data-selector]');
		var categories = [];

		for (var i = 0; i < inputs.length; i++) {
			if ($(inputs[i]).is(':checked')) {
				categories.push($(inputs[i]).data('selector'));
			}
		}

		var stores = $('[data-store]');

		if (categories.length == 0) {
			for (i = 0; i < stores.length; i++) {
				$(stores[i]).css('display', 'block');
			}
		} else {
			var categoriesText = '';
			var x = 0;
			var hit = false;

			for (i = 0; i < stores.length; i++) {
				categoriesText = $(stores[i]).data('store');
				hit = false;

				for(x = 0; x < categories.length; x++) {
					if (categoriesText.indexOf(categories[x]) !== -1) {
						hit = true;
					}
				}

				if (hit) {
					$(stores[i]).css('display', 'block');
				} else {
					$(stores[i]).css('display', 'none');
				}
			}
		}
	});
});
